"use client";

import { PayloadAction, Slice, createSlice } from "@reduxjs/toolkit";
import { formatDateToYYYYMMDD } from "utilities/tools/date";

type User = {
  email: string;
  firstName: string;
  lastName: string;
};

let firstDayMonth = new Date();
firstDayMonth.setDate(1);

export type CompanyHistoricSliceType = {
  filterUser: null | string;
  filterUniqDate: null | string;
  filterStartDate: null | string;
  filterEndDate: null | string;
  dateType: boolean;
  //
  filterByDate: boolean;
  filterTypeAction: boolean;
  filterTypeExchange: boolean;
  filterTypeImport: boolean;
  participants: User[];
  participantsSelected: string[];
};

const initialState: CompanyHistoricSliceType = {
  filterUser: null,
  filterUniqDate: formatDateToYYYYMMDD(new Date()),

  filterStartDate: formatDateToYYYYMMDD(firstDayMonth),
  filterEndDate: formatDateToYYYYMMDD(new Date()),
  filterTypeAction: true,
  filterTypeExchange: true,
  filterTypeImport: true,
  filterByDate: true,
  dateType: true,
  participants: [],
  participantsSelected: [],
};

export const companyHistoricSlice: Slice<CompanyHistoricSliceType> = createSlice({
  name: "companyHistoricData",
  initialState,

  reducers: {
    setFilterByDate: (state, action: PayloadAction<boolean>) => {
      state.filterByDate = action.payload;
      if (action.payload === true) {
        // state.filterUniqDate = null;

        state.filterStartDate = formatDateToYYYYMMDD(firstDayMonth);
        state.filterEndDate = formatDateToYYYYMMDD(new Date());
      } else {
        // state.filterStartDate = null;
        // state.filterEndDate = null;

        state.filterUniqDate = formatDateToYYYYMMDD(new Date());
      }
    },
    setFilterUser: (state, action: PayloadAction<string | null>) => {
      state.filterUser = action.payload;
    },
    setDateType: (state, action: PayloadAction<boolean>) => {
      state.dateType = action.payload;
    },
    setFilterUniqDate: (state, action: PayloadAction<string | null>) => {
      // state.filterStartDate = null;
      // state.filterEndDate = null;

      state.filterUniqDate = action.payload;
    },
    setFilterStartDate: (state, action: PayloadAction<string | null>) => {
      // state.filterUniqDate = null;

      state.filterStartDate = action.payload;
    },
    setFilterEndDate: (state, action: PayloadAction<string | null>) => {
      // state.filterUniqDate = null;

      state.filterEndDate = action.payload;
    },
    setFilterTypeAction: (state, action: PayloadAction<boolean>) => {
      state.filterTypeAction = action.payload;
    },
    setFilterTypeExchange: (state, action: PayloadAction<boolean>) => {
      state.filterTypeExchange = action.payload;
    },
    setFilterTypeImport: (state, action: PayloadAction<boolean>) => {
      state.filterTypeImport = action.payload;
    },
    setParticipants: (state, action: PayloadAction<User[]>) => {
      state.participants = action.payload;
    },
    resetBlackList: (state, action: PayloadAction<null>) => {
      state.participantsSelected = [];
    },
    setParticipantsSelected: (state, action: PayloadAction<User>) => {
      let copy = JSON.parse(JSON.stringify(state.participantsSelected));

      if (copy.includes(action.payload.email)) {
        let modified = copy.filter((item) => item != action.payload.email);
        state.participantsSelected = modified;
        return;
      } else {
        state.participantsSelected = [...copy, action.payload.email];
      }
    },
    resetParticipantsSelected: (state, action: PayloadAction<null>) => {
      state.participantsSelected = [];
    },
  },
});
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const {
  setFilterUser,
  setFilterUniqDate,
  setFilterStartDate,
  setFilterEndDate,
  setFilterTypeAction,
  setFilterTypeExchange,
  setFilterTypeImport,
  setFilterByDate,
  setParticipants,
  setParticipantsSelected,
  resetParticipantsSelected,
  setDateType,
} = companyHistoricSlice.actions;

export default companyHistoricSlice.reducer;
