import { PayloadAction, Slice, createSlice, current } from "@reduxjs/toolkit";
import { filterQuestionInCategory, updateFilteredCategory } from "utilities/redux/questions";
import { AccountType, AccountWithCategoriesType, PlayloadQuestionInCategoryType } from "utilities/redux/types";

export type questionArchivedSliceType = {
  accountsInView: number[];
  categoriesInView: number[];
  search: string;
  questions: AccountWithCategoriesType[];
};

const initialState: questionArchivedSliceType = {
  accountsInView: [],
  categoriesInView: [],
  search: "",
  questions: [],
};

export const QuestionArchivedSlice: Slice<questionArchivedSliceType> = createSlice({
  name: "questionArchivedData",
  initialState,
  reducers: {
    setCategories: (state, action: PayloadAction<AccountWithCategoriesType[]>) => {
      state.questions = action.payload;
    },
    setQuestions: (state, action: PayloadAction<{ categoryIndex: number; account: AccountType; }>) => {
      const accountIndex = state.questions[action.payload.categoryIndex].accounts.findIndex(
        (item) => item.id === action.payload?.account?.id,
      );

      if (accountIndex !== -1) {
        state.questions = [
          ...state.questions.slice(0, action.payload.categoryIndex),
          {
            ...state.questions[action.payload.categoryIndex],
            accounts: [
              ...state.questions[action.payload.categoryIndex].accounts.slice(0, accountIndex),
              {
                ...state.questions[action.payload.categoryIndex].accounts[accountIndex],
                ...action.payload.account,
              },
              ...state.questions[action.payload.categoryIndex].accounts.slice(accountIndex + 1),
            ],
          },
          ...state.questions.slice(action.payload.categoryIndex + 1),
        ];
      }
    },
    addAccountInView: (state, action: PayloadAction<number>) => {
      state.accountsInView = [...state.accountsInView, action.payload];
    },
    removeAccountInView: (state, action: PayloadAction<number>) => {
      state.accountsInView = state.accountsInView.filter((item) => action.payload !== item);
    },
    addCategoryInView: (state, action: PayloadAction<number>) => {
      state.categoriesInView = [...state.categoriesInView, action.payload];
    },
    removeCategoryInView: (state, action: PayloadAction<number>) => {
      state.categoriesInView = state.categoriesInView.filter((item) => action.payload !== item);
    },
    setSearchAccount: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },

    restoreQuestion: (state, action: PayloadAction<PlayloadQuestionInCategoryType>) => {
      const { categoryIndex, accountId, questionGroupIndex, questionIndex } = action.payload;
      const accountIndex = state.questions?.[categoryIndex]?.accounts?.findIndex(account => account.id === accountId);
      let clonedQuestions = current(state.questions) as any[];

      const temp = filterQuestionInCategory(
        clonedQuestions,
        categoryIndex,
        accountIndex,
        questionGroupIndex,
        questionIndex,
      );
      state.questions = temp.questions;
    },
    restoreCategory: (state, action: PayloadAction<number>) => {
      state.questions = updateFilteredCategory(state.search, action.payload, state.questions);
    },
    resetArchived: (state, action: PayloadAction<null>) => {
      state.questions = [];
    },
  },
});

export const {
  setCategories,
  addAccountInView,
  removeAccountInView,
  addCategoryInView,
  removeCategoryInView,
  setSearchAccount,
  setQuestions,

  restoreQuestion,
  restoreCategory,
  resetArchived,
} = QuestionArchivedSlice.actions;

export default QuestionArchivedSlice.reducer;
