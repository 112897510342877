import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";
import {
  AccountingFirmConnectorType,
  AccountingFirmConnectorTypeType,
  AccountType,
  CompanyType,
  JournalNatureType,
  JournalType,
  QuestionCategoryType,
  EntryImportType as Type,
} from "utilities/redux/types";
import { sortAccountsByDirectionAndDomain } from "./_helpers/EntryImportHelpers";

export type EntryImportType = {
  dates: {
    referenceDate: string;
    puchaseTimeLimit: number;
    saleTimeLimit: number;
  };
  partialLettering: string;
  accountingFirmConnnector: AccountingFirmConnectorType;
  journaux: JournalType[];
  journalNatures: JournalNatureType[];
  categories: QuestionCategoryType[];
  fec: File | {};
  accounts: AccountType[];

  displayAccounts: AccountType[];
  selectedAccounts: AccountType[];
  selectedCategories: QuestionCategoryType[];
  searchAccounts: string;
  sortAccountDomain: "code" | "legend" | "";
  sortAccountDirection: "ASC" | "DESC" | "";
  ///
  displayIncludedAccounts: AccountType[];
  selectedIncludedAccounts: AccountType[];
  selectedIncludedCategories: QuestionCategoryType[];
  searchIncludedAccounts: string;
  sortIncludedAccountDomain: "code" | "legend" | "";
  sortIncludedAccountDirection: "ASC" | "DESC" | "";
  ///
  connectors: AccountingFirmConnectorType[];
  usableConnectors: AccountingFirmConnectorType[];
  nonUsableConnectors: AccountingFirmConnectorType[];
  connectorSelected: AccountingFirmConnectorType | null;

  accountState: "all" | "show" | "hide";
  accountIncludedState: "all" | "show" | "hide";
  currentImportData: any;
  prevImports: Type[];
  lastImport: Type | null;
  prevImportIsLoading: boolean;

  prevImportSelected: number | null;
  prevImportEntriesIsOpen: boolean;
  prevImportQuestionsIsOpen: boolean;
  prevImportDeleteConfirmation: boolean;
  prevImportConfirmDeleteIsOpen: boolean;

  /// anti-doublon
  removeDuplicateQuestions: boolean;
};

let temp = new Date();
temp?.setDate(0);

const initialState: EntryImportType = {
  dates: {
    referenceDate: temp?.toISOString()?.substring(0, 10),
    puchaseTimeLimit: 15,
    saleTimeLimit: 45,
  },
  journaux: [],
  journalNatures: [],
  categories: [],
  fec: {},
  partialLettering: "",
  accountingFirmConnnector: null,

  accounts: [],
  displayAccounts: [],
  selectedAccounts: [],
  selectedCategories: [],
  searchAccounts: "",
  sortAccountDomain: "",
  sortAccountDirection: "",
  accountState: "all",
  ///// acountsSelected
  displayIncludedAccounts: [],
  selectedIncludedAccounts: [],
  selectedIncludedCategories: [],
  searchIncludedAccounts: "",
  sortIncludedAccountDomain: "",
  sortIncludedAccountDirection: "",
  accountIncludedState: "all",

  //
  connectors: [],
  usableConnectors: [],
  nonUsableConnectors: [],
  connectorSelected: null,

  //// search if entry import already on state 1
  prevImports: [],
  lastImport: null,
  prevImportSelected: null,
  prevImportEntriesIsOpen: false,
  prevImportQuestionsIsOpen: false,
  prevImportDeleteConfirmation: false,
  prevImportConfirmDeleteIsOpen: false,
  //
  currentImportData: [],
  prevImportIsLoading: true,

  //
  removeDuplicateQuestions: true,
};

export const EntryImportSlice: Slice<EntryImportType> = createSlice({
  name: "entryImportData",
  initialState,

  reducers: {
    ////////////////////////////// PREV ENTRY IMPORTS ////////////////////////////////
    setLastImport: (state, action: PayloadAction<Type>) => {
      state.lastImport = action.payload;
    },
    setPrevImports: (state, action: PayloadAction<Type[]>) => {
      state.prevImports = action.payload;
    },
    updatePrevImport: (state, action: PayloadAction<Type>) => {
      const index = state.prevImports.findIndex(i => i.id === action.payload.id);

      if (index > 0) {
        state.prevImports = [
          ...state.prevImports.slice(0, index),
          action.payload,
          ...state.prevImports.slice(index + 1),
        ];
      }
    },
    setPrevImport: (state, action: PayloadAction<{ index: number; entryImport: Type; }>) => {
      const prevImportsCopy = JSON.parse(JSON.stringify(state.prevImports));

      prevImportsCopy[action.payload.index] = action.payload.entryImport;

      state.prevImports = prevImportsCopy;
    },
    setPrevImportLoading: (state, action: PayloadAction<boolean>) => {
      state.prevImportIsLoading = action.payload;
    },
    setCurrentImportData: (state, action: PayloadAction<any>) => {
      state.currentImportData = action.payload;
    },

    setPrevImportSelected: (state, action: PayloadAction<number>) => {
      state.prevImportSelected = action.payload;
    },
    setPrevImportQuestionsIsOpen: (state, action: PayloadAction<boolean>) => {
      state.prevImportQuestionsIsOpen = action.payload;
    },
    setPrevImportEntriesIsOpen: (state, action: PayloadAction<boolean>) => {
      state.prevImportEntriesIsOpen = action.payload;
    },
    setPrevImportDeleteConfirmationIsOpen: (state, action: PayloadAction<boolean>) => {
      state.prevImportConfirmDeleteIsOpen = action.payload;
    },
    setPartialLettering: (state, action: PayloadAction<string>) => {
      state.partialLettering = action.payload;
    },
    setAccountingFirmConnector: (state, action: PayloadAction<AccountingFirmConnectorType>) => {
      state.accountingFirmConnnector = action.payload;
    },

    ////////////////////////////// CONNECTORS ////////////////////////////////
    setConnectors: (state, action: PayloadAction<AccountingFirmConnectorType[]>) => {
      state.connectors = action.payload;
    },
    setConnectorSelected: (state, action: PayloadAction<AccountingFirmConnectorType>) => {
      state.connectorSelected = action.payload;
    },
    updateConnector: (state, action: PayloadAction<AccountingFirmConnectorType>) => {
      const connector = action.payload;
      const connectorIndex = state.connectors?.findIndex((c) => c.id === connector?.id);

      if (connectorIndex !== -1) {
        state.connectors = [
          ...state.connectors.slice(0, connectorIndex),
          connector,
          ...state.connectors.slice(connectorIndex + 1),
        ];
      }

      if (connector?.id === state.connectorSelected?.id) {
        state.connectorSelected = action?.payload;
      }

      const usableConnectorIndex = state.usableConnectors?.findIndex((c) => c.id === connector?.id);

      if (usableConnectorIndex !== -1) {
        state.usableConnectors = [
          ...state.usableConnectors.slice(0, usableConnectorIndex),
          connector,
          ...state.usableConnectors.slice(usableConnectorIndex + 1),
        ];
      }

      const nonUsableConnectorIndex = state.nonUsableConnectors?.findIndex((c) => c.id === connector?.id);

      if (nonUsableConnectorIndex !== -1) {
        state.nonUsableConnectors = [
          ...state.nonUsableConnectors.slice(0, nonUsableConnectorIndex),
          connector,
          ...state.nonUsableConnectors.slice(nonUsableConnectorIndex + 1),
        ];
      }
    },

    setUsableConnectors: (
      state,
      action: PayloadAction<{
        connectors: AccountingFirmConnectorType[];
        defaultConnectorType: AccountingFirmConnectorTypeType;
        company: CompanyType;
      }>,
    ) => {
      const { connectors, defaultConnectorType, company } = action.payload;

      state.usableConnectors = connectors;

      if (state.connectorSelected) {
        /// récupère le dernier connecteur sélectionné
        const updatedConnectorSelected = connectors.find(
          (c) => c.accountingFirmConnectorType.value === state.connectorSelected?.accountingFirmConnectorType?.value,
        );

        state.connectorSelected = updatedConnectorSelected;
      } else if (connectors?.length > 0) {
        /// si il ya un import et qu'il est utilisable
        if (company?.lastImport) {
          const lastConnectorIndex = connectors?.findIndex(
            (c) =>
              c.accountingFirmConnectorType.value ===
              company?.lastImport?.accountingFirmConnector?.accountingFirmConnectorType?.value,
          );

          if (lastConnectorIndex !== -1) {
            state.connectorSelected = connectors[lastConnectorIndex];
            return;
          }
        }

        const defaultCompanyConnectorIndex = connectors?.findIndex(
          (c) => c.accountingFirmConnectorType.value === company?.creationMethod?.value,
        );

        const defaultConnectorIndex = connectors?.findIndex(
          (c) => c.accountingFirmConnectorType.value === defaultConnectorType?.value,
        );

        if (defaultCompanyConnectorIndex !== -1) {
          state.connectorSelected = connectors[defaultCompanyConnectorIndex];
        } else if (defaultConnectorIndex !== -1) {
          state.connectorSelected = connectors[defaultConnectorIndex];
        } else {
          state.connectorSelected = connectors[0];
        }
      }
    },
    setNonUsableConnectors: (state, action: PayloadAction<AccountingFirmConnectorType[]>) => {
      state.nonUsableConnectors = action.payload;
    },

    ////////////////////////////// DATES ////////////////////////////////
    setDate: (state, action: PayloadAction) => {
      let datesCopy = JSON.parse(JSON.stringify(state.dates));
      datesCopy.referenceDate = action.payload;
      state.dates = datesCopy;
    },
    setPurchase: (state, action: PayloadAction) => {
      let datesCopy = JSON.parse(JSON.stringify(state.dates));
      datesCopy.puchaseTimeLimit = action.payload;
      state.dates = datesCopy;
    },
    setSale: (state, action: PayloadAction) => {
      let datesCopy = JSON.parse(JSON.stringify(state.dates));
      datesCopy.saleTimeLimit = action.payload;
      state.dates = datesCopy;
    },

    ////////////////////////////// JOURNAUX ////////////////////////////////
    setJournals: (state, action: PayloadAction<JournalType[]>) => {
      state.journaux = action.payload;
    },
    setJournalNatures: (state, action: PayloadAction<JournalNatureType[]>) => {
      state.journalNatures = action.payload;
    },

    setNature: (
      state,
      action: PayloadAction<{
        id: number;
        value: "A" | "V" | "T" | "N" | "S" | "O";
      }>,
    ) => {
      let journauxCopy = JSON.parse(JSON.stringify(state.journaux));
      const journalIndex = journauxCopy.findIndex((journal: any) => journal.id === action.payload.id);
      journauxCopy[journalIndex].nature = action.payload.value;
      state.journaux = journauxCopy;
    },

    ////////////////////////////// CATEGORIES ////////////////////////////////
    toogleCategory: (state, action: PayloadAction<QuestionCategoryType>) => {
      let selectedCat = JSON.parse(JSON.stringify(state.selectedCategories));

      if (selectedCat.some((item: QuestionCategoryType) => item.id === action.payload.id)) {
        selectedCat = selectedCat.filter((item: QuestionCategoryType) => item.id != action.payload.id);
        state.selectedCategories = selectedCat;
      } else {
        selectedCat = [action.payload, ...selectedCat];
        state.selectedCategories = selectedCat;
      }
    },
    setCategories: (state, action: PayloadAction<QuestionCategoryType[]>) => {
      let categoriesCopy = JSON.parse(JSON.stringify(action.payload));
      const categoryIndex = categoriesCopy.findIndex((category: any) => category.code === "CL");

      categoriesCopy[categoryIndex].includeFileNumber = true;

      state.categories = categoriesCopy;
    },
    setSelectedCategories: (state, action: PayloadAction<QuestionCategoryType[]>) => {
      state.selectedCategories = action.payload;
    },

    setIncludeFileNumber: (state, action: PayloadAction<{ id: number; value: boolean; }>) => {
      let categoriesCopy = JSON.parse(JSON.stringify(state.categories));
      const categoryIndex = categoriesCopy.findIndex((category: any) => category.id === action.payload.id);
      categoriesCopy[categoryIndex].includeFileNumber = action.payload.value;
      state.categories = categoriesCopy;
    },
    setCategoryName: (state, action: PayloadAction<{ id: number; value: string; }>) => {
      let categoriesCopy = JSON.parse(JSON.stringify(state.categories));
      const categoryIndex = categoriesCopy.findIndex((category: any) => category.id === action.payload.id);
      categoriesCopy[categoryIndex].name = action.payload.value;
      state.categories = categoriesCopy;
    },
    setIconName: (state, action: PayloadAction<{ id: number; value: string; }>) => {
      let categoriesCopy = JSON.parse(JSON.stringify(state.categories));
      const categoryIndex = categoriesCopy.findIndex((category: any) => category.id === action.payload.id);
      categoriesCopy[categoryIndex].icon = action.payload.value;
      state.categories = categoriesCopy;
    },

    addCategory: (state, action: PayloadAction<QuestionCategoryType>) => {
      let categoriesCopy = JSON.parse(JSON.stringify(state.categories));
      categoriesCopy.push(action.payload);
      state.categories = categoriesCopy;
    },
    deleteCategory: (state, action: PayloadAction<number>) => {
      let categoriesCopy = JSON.parse(JSON.stringify(state.categories));
      state.categories = categoriesCopy.filter((category: QuestionCategoryType) => category.id !== action.payload);
    },

    // Fec
    addFec: (state, action: PayloadAction<File>) => {
      state.fec = action.payload;
    },

    // Account ///////////////////////////////////////////

    setSortAccountDomain: (state, action: PayloadAction<"code" | "legend">) => {
      state.sortAccountDomain = action.payload;
      if (!state.displayAccounts || state?.displayAccounts?.length == 0 || !Array.isArray(state.displayAccounts)) {
        return;
      }
      let clonedDisplayedAccounts = JSON.parse(JSON.stringify(state.displayAccounts)) as AccountType[];

      if (state.sortAccountDirection === "DESC") {
        state.sortAccountDirection = "ASC";

        state.displayAccounts = sortAccountsByDirectionAndDomain(clonedDisplayedAccounts, "ASC", action.payload);
        return;
      }
      if (state.sortAccountDirection === "ASC") {
        state.sortAccountDirection = "DESC";
        state.displayAccounts = sortAccountsByDirectionAndDomain(clonedDisplayedAccounts, "DESC", action.payload);
        return;
      }
      state.sortAccountDirection = "DESC";
      state.displayAccounts = sortAccountsByDirectionAndDomain(clonedDisplayedAccounts, "DESC", action.payload);
    },
    setAccounts: (state, action: PayloadAction<AccountType[]>) => {
      state.accounts = action.payload;
    },
    setAccountState: (state, action: PayloadAction<"all" | "show" | "hide">) => {
      state.accountState = action.payload;
    },
    setDisplayedAccounts: (state, action: PayloadAction<AccountType[]>) => {
      state.displayAccounts = action.payload;
    },
    setSelectedAccounts: (state, action: PayloadAction<AccountType[]>) => {
      state.selectedAccounts = action.payload;
    },
    setSearchAccount: (state, action: PayloadAction<string>) => {
      state.searchAccounts = action.payload;
    },
    filterWithSearchAccounts: (state, action: PayloadAction<string>) => {
      let copy;

      if (state.accountState === "all") {
        copy = JSON.parse(JSON.stringify(state.accounts));
      }
      if (state.accountState === "show") {
        copy = JSON.parse(JSON.stringify(state.selectedAccounts));
      }
      if (state.accountState === "hide") {
        copy = JSON.parse(
          JSON.stringify(state.accounts.filter((item) => !state.selectedAccounts.some((t) => t.id === item.id))),
        );
      }

      /// filter with result
      let final = copy.filter((item: AccountType) => {
        if (
          item.legend?.toLowerCase().includes(state.searchAccounts?.toLowerCase()) ||
          item.code?.toLowerCase().includes(state.searchAccounts?.toLowerCase())
        ) {
          return item;
        }
      });

      let clonedSortAccountDirection = (state.displayAccounts = sortAccountsByDirectionAndDomain(
        final,
        state.sortAccountDirection,
        state.sortAccountDomain,
      ));
    },
    toogleAccountItem: (state, action: PayloadAction<AccountType>) => {
      let copy = JSON.parse(JSON.stringify(state.selectedAccounts));

      if (copy.some((item: AccountType) => item.id === action.payload.id)) {
        copy = copy?.filter((item: AccountType) => item.id !== action.payload.id);
      } else {
        copy = [action.payload, ...copy];
      }
      state.selectedAccounts = copy;
    },

    showAccount: (state, action: PayloadAction) => {
      let accountsCopy = JSON.parse(JSON.stringify(state.accounts));
      const accountIndex = accountsCopy?.findIndex((account: any) => account.id === action.payload);
      accountsCopy[accountIndex].isVisible = true;
      state.accounts = accountsCopy;
    },
    hideAccount: (state, action: PayloadAction) => {
      let accountsCopy = JSON.parse(JSON.stringify(state.accounts));
      const accountIndex = accountsCopy?.findIndex((account: any) => account.id === action.payload);
      accountsCopy[accountIndex].isVisible = false;
      state.accounts = accountsCopy;
    },

    // Account Selected ///////////////////////////////////////////
    setSortAccountIncludedDomain: (state, action: PayloadAction<"code" | "legend">) => {
      state.sortIncludedAccountDomain = action.payload;
      let clonedDisplayedAccounts = JSON.parse(JSON.stringify(state.displayAccounts)) as AccountType[];

      if (state.sortIncludedAccountDirection === "DESC") {
        state.sortIncludedAccountDirection = "ASC";
        state.displayIncludedAccounts = sortAccountsByDirectionAndDomain(
          clonedDisplayedAccounts,
          "ASC",
          action.payload,
        );
        return;
      } else if (state.sortIncludedAccountDirection === "ASC") {
        state.sortIncludedAccountDirection = "DESC";
        state.displayIncludedAccounts = sortAccountsByDirectionAndDomain(
          clonedDisplayedAccounts,
          "DESC",
          action.payload,
        );
        return;
      }
      state.sortIncludedAccountDirection = "DESC";
      state.displayIncludedAccounts = sortAccountsByDirectionAndDomain(clonedDisplayedAccounts, "DESC", action.payload);
    },

    setAccountIncludedState: (state, action: PayloadAction<"all" | "show" | "hide">) => {
      state.accountIncludedState = action.payload;
    },
    setDisplayedIncludedAccounts: (state, action: PayloadAction<AccountType[]>) => {
      state.displayIncludedAccounts = action.payload;
    },
    setSelectedIncludedAccounts: (state, action: PayloadAction<AccountType[]>) => {
      state.selectedIncludedAccounts = action.payload;
    },
    setSearchIncludedAccount: (state, action: PayloadAction<string>) => {
      state.searchIncludedAccounts = action.payload;
    },
    filterWithSearchIncludedAccounts: (state, action: PayloadAction<string>) => {
      let copy;

      if (state.accountIncludedState === "all") {
        copy = JSON.parse(JSON.stringify(state.accounts));
      }
      if (state.accountIncludedState === "show") {
        copy = JSON.parse(JSON.stringify(state.selectedIncludedAccounts));
      }
      if (state.accountIncludedState === "hide") {
        copy = JSON.parse(
          JSON.stringify(
            state.accounts.filter((item) => !state.selectedIncludedAccounts?.some((t) => t.id === item.id)),
          ),
        );
      }

      /// filter with result
      let final = copy.filter((item: AccountType) => {
        if (
          item.legend?.toLowerCase().includes(state.searchIncludedAccounts?.toLowerCase()) ||
          item.code?.toLowerCase().includes(state.searchIncludedAccounts?.toLowerCase())
        ) {
          return item;
        }
      });

      state.displayIncludedAccounts = sortAccountsByDirectionAndDomain(
        final,
        state.sortAccountDirection,
        state.sortAccountDomain,
      );
    },
    toogleAccountIncludedItem: (state, action: PayloadAction<AccountType>) => {
      let copy = JSON.parse(JSON.stringify(state.selectedIncludedAccounts));

      if (copy.some((item: AccountType) => item.id === action.payload.id)) {
        copy = copy?.filter((item: AccountType) => item.id !== action.payload.id);
      } else {
        copy = [action.payload, ...copy];
      }
      state.selectedIncludedAccounts = copy;
    },

    ////////////////////////////// ANTI DOUBLONS ////////////////////////////////

    setRemoveDuplicateQuestions: (state, action: PayloadAction<boolean>) => {
      state.removeDuplicateQuestions = action.payload;
    },
  },
});
export const {
  setAccountIncludedState,
  setDisplayedIncludedAccounts,
  setSelectedIncludedAccounts,
  setSearchIncludedAccount,
  toogleAccountIncludedItem,
  filterWithSearchIncludedAccounts,
  setSortAccountIncludedDomain,
  ////
  setJournals,
  setCategories,
  setAccountState,
  setCurrentImportData,
  setAccountingFirmConnector,
  toogleCategory,
  setAccounts,
  setJournalNatures,
  setPartialLettering,
  setLastImport,
  setPrevImports,
  updatePrevImport,
  setPrevImport,
  setPrevImportLoading,
  setDisplayedAccounts,
  setSelectedAccounts,
  filterWithSearchAccounts,
  toogleAccountItem,
  setSearchAccount,
  setConnectors,
  setDate,
  setPurchase,
  setSale,
  setNature,
  selectCategory,
  unselectCategory,
  setIncludeFileNumber,
  setCategoryName,
  setSelectedCategories,
  setIconName,
  addCategory,
  deleteCategory,
  addFec,
  showAccount,
  hideAccount,
  setConnectorSelected,
  updateConnector,
  setUsableConnectors,
  setNonUsableConnectors,
  setSortAccountDirection,
  setSortAccountDomain,
  setPrevImportSelected,
  setPrevImportQuestionsIsOpen,
  setPrevImportEntriesIsOpen,
  setPrevImportDeleteConfirmationIsOpen,
  setRemoveDuplicateQuestions,
} = EntryImportSlice.actions;

export default EntryImportSlice.reducer;
