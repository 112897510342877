import { EntryExerciceType, EntryGraphAccountTypeType, EntryGraphType } from "../../../../utilities/redux/types";
import { diffDays } from "../../../../utilities/tools/date";
import { BalanceType } from "../AccountingChart";

export default function shapeData(data: Array<{
  graph: EntryGraphType,
  exercices: Array<{
    exercice: EntryExerciceType,
    values: Array<{
      type: EntryGraphAccountTypeType,
      balances: BalanceType[];
    }>;
  }>;
}>, maxNbExercice: number | null = null) {
  let graphs = data;

  graphs = graphs?.map(g => {
    const filteredExercices = g.exercices.filter((e) => e.values && e?.values.length > 0);
    const sortedExercices = filteredExercices.sort((a, b) => diffDays(a.exercice.startAt, b.exercice.startAt));
    const exerciceStarts = [... new Set(sortedExercices.map(e => e.exercice.startAt))];

    const keepedExerciceStart = maxNbExercice ? exerciceStarts.slice(-maxNbExercice) : exerciceStarts;

    return {
      ...g,
      exercices: sortedExercices.filter(e => keepedExerciceStart.includes(e.exercice.startAt)),
      graph: {
        ...g.graph,
        graphExercice: g.graph.graphExercices.sort((a, b) => diffDays(a.exercice.startAt, b.exercice.startAt)),
      },
    };
  });

  return graphs?.sort((a, b) => {
    if (a.graph.graphType.organization && b.graph.graphType.organization) {
      return a.graph.graphType.organization - b.graph.graphType.organization;
    } else if (a.graph.graphType.organization) { return 1; } else { return -1; }
  }) ?? [];
}