"use client";

import { PayloadAction, Slice, createSlice } from "@reduxjs/toolkit";
import { CompanyType } from "utilities/redux/types";

export type NavigationSliceType = {
  search: string;
  criteria: string;
  companies: CompanyType[];
  companySelected: CompanyType | null;
  companyMenuIsOpen: boolean;
  step: "search" | "menu" | "pes";
  prestep: "search" | "menu" | "pes" | "";
  burgerMenuIsOpen: boolean;
  ///
  globalSearchIsOpen: boolean;
  filterCompanies: boolean;
  filterPages: boolean;
};

const initialState: NavigationSliceType = {
  prestep: "",
  step: "search",
  search: "",
  criteria: "",
  companies: [],
  companySelected: null,
  /// burger
  burgerMenuIsOpen: false,
  companyMenuIsOpen: false,
  ///
  globalSearchIsOpen: false,
  filterCompanies: true,
  filterPages: true,
};

export const navigationSlice: Slice<NavigationSliceType> = createSlice({
  name: "navigationData",
  initialState,

  reducers: {
    /// BURGER MENU
    setBurgerMenuIsOpen: (state, action: PayloadAction<boolean>) => {
      state.burgerMenuIsOpen = action.payload;
    },
    setCompanyMenuIsOpen: (state, action: PayloadAction<boolean>) => {
      state.companyMenuIsOpen = action.payload;
    },
    //// Global Search - pages et dossiers
    setGlobalSearchIsOpen: (state, action: PayloadAction<boolean>) => {
      state.globalSearchIsOpen = action.payload;
    },
    setFilterCompanies: (state, action: PayloadAction<boolean>) => {
      state.filterCompanies = action.payload;
    },
    setFilterPages: (state, action: PayloadAction<boolean>) => {
      state.filterPages = action.payload;
    },
  },
});
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const { setFilterCompanies, setFilterPages, setGlobalSearchIsOpen, setBurgerMenuIsOpen, setCompanyMenuIsOpen } =
  navigationSlice.actions;

export default navigationSlice.reducer;
