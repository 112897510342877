import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";
import { QuestionBluePrintType } from "utilities/redux/types";

export type QuestionBluePrintSliceType = {
  bluePrints: QuestionBluePrintType[];
  bluePrintSelected: QuestionBluePrintType;
};

const initialState: QuestionBluePrintSliceType = {
  bluePrints: [],
  bluePrintSelected: null,
};

export const QuestionBluePrintSlice: Slice<QuestionBluePrintSliceType> = createSlice({
  name: "questionBluePrintData",
  initialState,

  reducers: {
    setBluePrints: (state, action: PayloadAction<QuestionBluePrintType[]>) => {
      state.bluePrints = action.payload;
    },
    setBluePrintSelected: (state, action: PayloadAction<QuestionBluePrintType>) => {
      state.bluePrintSelected = action.payload;
    },
  },
});
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const { setBluePrints, setBluePrintSelected } = QuestionBluePrintSlice.actions;

export default QuestionBluePrintSlice.reducer;
