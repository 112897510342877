import { PayloadAction, Slice, createSlice } from "@reduxjs/toolkit";
import { CriteriaType } from "ui/components/inputs";
import { BalanceType } from "ui/modules/accountingChart";
import shapeData from "ui/modules/accountingChart/utilities/shapeData";
import {
  CompanyConnectorPairingKeyType,
  CompanyCounterType,
  CompanyPermissionType,
  CompanyType,
  EntryExerciceType,
  EntryGraphAccountTypeType,
  EntryGraphType,
  NotificationExpertsByCompaniesType,
  QuestionAttachmentTransmissionApiType,
  QuestionAttachmentTransmissionMailType,
  QuestionCategoryType,
  UserType,
} from "utilities/redux/types";
import { diffDays } from "utilities/tools/date";

export type CompanySearchType = "user" | "accountingFirm" | "notification" | "created";

type CompanyDataType = {
  searchType: CompanySearchType;

  lastCompanyAction: any;
  companiesToAnswer: any;
  companySelected: CompanyType;
  appairingKey: CompanyConnectorPairingKeyType;
  graphs: Array<{
    graph: EntryGraphType;
    exercices: Array<{
      exercice: EntryExerciceType;
      values: Array<{
        type: EntryGraphAccountTypeType;
        balances: BalanceType[];
      }>;
    }>;
  }>;
  tranmissionMails: QuestionAttachmentTransmissionMailType[];
  tranmissionApis: { [type: string]: QuestionAttachmentTransmissionApiType[]; };
  exercices: Array<EntryExerciceType>;
  companyPicture: any;
  criteria: CriteriaType;
  companiesResearched: CompanyType[];
  companiesResearchedAndFiltered: CompanyType[];
  search: string;
  companyPagesCount: number;
  /// PAGINATION
  currentPage: number;
  sortFieldName: string;
  sortFieldDirection: "desc" | "asc";
  /// FILTRATION
  connector: string;
  userAffected: string | UserType;
  access: string;
  state: string;
  notification: string;
  companiesAffected: CompanyType[];
  closingMonth: string;
};

const initialState: CompanyDataType = {
  searchType: "user",

  companiesToAnswer: [],
  companySelected: null,
  tranmissionMails: null,
  tranmissionApis: null,
  graphs: null,
  exercices: [],
  companyPicture: null,
  search: "",
  criteria: null,
  companyPagesCount: 1,
  lastCompanyAction: null,
  appairingKey: null,
  companiesResearched: [],
  companiesResearchedAndFiltered: [],
  // PAGINATION
  currentPage: 1,
  sortFieldName: "code",
  sortFieldDirection: "asc",
  // FILTRATION
  connector: "all",
  userAffected: "all",
  access: "all",
  state: "all",
  notification: "all",
  companiesAffected: [],
  closingMonth: "all",
};

export const CompanySlice: Slice<CompanyDataType> = createSlice({
  name: "companiesData",
  initialState,
  reducers: {
    setCompanySearchType: (state, action: PayloadAction<CompanySearchType>) => {
      state.searchType = action.payload;
    },

    setCompanySelected: (state, action: PayloadAction<CompanyType>) => {
      state.companySelected = action.payload
        ? {
          ...state.companySelected,
          ...action.payload,
          entryExercices: action?.payload?.entryExercices?.sort((a, b) => -diffDays(a?.startAt, b?.startAt)) ?? null,
        }
        : null;
    },
    setCompanyAffected: (state, action: PayloadAction<CompanyType[]>) => {
      state.companiesAffected = action.payload;
    },
    setCompanySelectedAppairingKey: (state, action: PayloadAction<CompanyConnectorPairingKeyType>) => {
      state.appairingKey = action.payload;
    },

    setDashboardCompanySelected: (
      state,
      action: PayloadAction<{
        data: Array<{
          graph: EntryGraphType;
          exercices: Array<{
            exercice: EntryExerciceType;
            values: Array<{
              type: EntryGraphAccountTypeType;
              balances: BalanceType[];
            }>;
          }>;
        }>;
        maxNbExercice: number | null;
      }>,
    ) => {
      state.graphs = shapeData(action.payload.data, action.payload.maxNbExercice);

      const exercices = action.payload.data?.[0]?.exercices
        ?.map((i) => i.exercice)
        .sort((a, b) => diffDays(a.startAt, b.startAt));
      state.exercices = exercices ?? [];
    },

    // updateDashboard: (
    //   state,
    //   action: PayloadAction<{
    //     id: number;
    //     data: Array<{
    //       exercice: EntryExerciceType;
    //       graphs: Array<{ graph: EntryGraphType; values: Array<{ type: EntryGraphAccountTypeType; balances: BalancesType[]; }>; }>;
    //     }>;
    //   }>
    // ) => {
    //   const updatedGraph = shapeData(action.payload.data)[0];

    //   const index = state.graphs?.findIndex(graph => graph?.id === action.payload?.id);

    //   if (index !== -1) {
    //     state.graphs = [
    //       ...state.graphs.slice(0, index),
    //       updatedGraph,
    //       ...state.graphs.slice(index + 1),
    //     ];
    //   } else {
    //     state.graphs = [
    //       ...state.graphs,
    //       updatedGraph,
    //     ];
    //   }
    // },

    setExercicesCompanySelected: (state, action: PayloadAction<EntryExerciceType[]>) => {
      let copy = action.payload ? JSON.parse(JSON.stringify(action.payload)) : [];
      copy?.sort((a, b) => diffDays(a.startAt, b.startAt));
      state.exercices = copy;
    },

    updateSelectedCompanyPicture: (state, action: PayloadAction<any>) => {
      if (state.companySelected !== null) {
        let copy = JSON.parse(JSON.stringify(state.companySelected));
        state.companyPicture = action.payload;
        copy.isPictureDefined = true;

        state.companySelected = copy;
      }
    },
    setLastCompanyAction: (state, action: PayloadAction<any>) => {
      state.lastCompanyAction = action.payload;
    },
    setCompaniesToAnswer: (state, action: PayloadAction<any>) => {
      state.companiesToAnswer = action.payload;
    },
    changeCompanyInformations: (state, action: PayloadAction<CompanyType>) => {
      let copy = JSON.parse(JSON.stringify(state.companySelected));
      state.companySelected = { ...copy, ...action.payload };
    },

    // USERS
    updateUser: (state, action: PayloadAction<UserType>) => {
      const userIndex = state.companySelected.users.findIndex((item) => item.id === action.payload.id);

      if (userIndex !== -1) {
        state.companySelected = {
          ...state.companySelected,
          users: [
            ...state.companySelected.users.slice(0, userIndex),
            action.payload,
            ...state.companySelected.users.slice(userIndex + 1),
          ],
        };
      }
    },

    // Collaborators

    addNotificationUser: (state, action: PayloadAction<NotificationExpertsByCompaniesType>) => {
      const companySelectedCopy = JSON.parse(JSON.stringify(state.companySelected));

      companySelectedCopy.notificationExpertByCompanies.push(action.payload);

      state.companySelected = companySelectedCopy;
    },

    removeNotificationUser: (state, action: PayloadAction<NotificationExpertsByCompaniesType>) => {
      const companySelectedCopy = JSON.parse(JSON.stringify(state.companySelected));

      companySelectedCopy.notificationExpertByCompanies = companySelectedCopy.notificationExpertByCompanies.filter(
        (notif: NotificationExpertsByCompaniesType) => notif.user.email !== action.payload.user.email,
      );

      state.companySelected = companySelectedCopy;
    },
    storeCollaborators: (state, action: PayloadAction<{ companySlug: string; collaborators: UserType[]; }>) => {
      state.companySelected = { ...state.companySelected, users: action.payload.collaborators };
    },

    addCollaborator: (state, action: PayloadAction<{ companySlug: string; collaborator: UserType; }>) => {
      let copy = JSON.parse(JSON.stringify(state.companySelected));

      copy.users.push(action.payload.collaborator);

      state.companySelected = copy;
    },

    deleteCollaborator: (state, action: PayloadAction<number>) => {
      let copy = JSON.parse(JSON.stringify(state.companySelected));
      copy.users = copy.users.filter((user: UserType) => user.id !== action.payload);
      state.companySelected = copy;
    },

    // Categories
    addCategory: (state, action: PayloadAction<{ companySlug: string; }>) => {
      let companiesCopy = JSON.parse(JSON.stringify(state.companySelected));
      const newCategory = {
        title: "",
        icon: "GoMortarBoard",
        id: -1,
        isDefault: false,
      };
      companiesCopy.questionCategories.push(newCategory);
      state.companySelected = companiesCopy;
    },

    changeCategory: (
      state,
      action: PayloadAction<{
        companySlug: string;
        newCategoryId: number;
        newCategory: QuestionCategoryType;
      }>,
    ) => {
      let companiesCopy = JSON.parse(JSON.stringify(state.companySelected));

      let categoriesCopie: QuestionCategoryType[] = companiesCopy.questionCategories;
      const categoryIndex = categoriesCopie.findIndex(
        (category: QuestionCategoryType) => category.id === action.payload.newCategoryId,
      );

      companiesCopy.questionCategories[categoryIndex] = action.payload.newCategory;

      state.companySelected = companiesCopy;
    },

    deleteCategory: (state, action: PayloadAction<number>) => {
      let companiesCopy = JSON.parse(JSON.stringify(state.companySelected));
      companiesCopy.questionCategories = companiesCopy.questionCategories.filter(
        (category: QuestionCategoryType) => category.id !== action.payload,
      );
      state.companySelected = companiesCopy;
    },

    storeCategories: (state, action: PayloadAction<QuestionCategoryType[]>) => {
      let companiesCopy = JSON.parse(JSON.stringify(state.companySelected));
      companiesCopy.questionCategories = action.payload;

      state.companySelected = companiesCopy;
    },

    storeTransmissionMails: (state, action: PayloadAction<QuestionAttachmentTransmissionMailType[]>) => {
      state.tranmissionMails = action.payload;
    },

    updateTransmissionMails: (state, action: PayloadAction<QuestionAttachmentTransmissionMailType[]>) => {
      state.tranmissionMails = action.payload;
    },

    storeTransmissionApis: (state, action: PayloadAction<{
      type: string;
      transmissions: QuestionAttachmentTransmissionApiType[];
    }>) => {
      const type = action.payload?.type;

      if (type) {
        state.tranmissionApis = {
          ...state.tranmissionApis,
          [type]: action.payload.transmissions
        };
      } else {
        state.tranmissionApis = null;
      }
    },

    updateTransmissionApis: (state, action: PayloadAction<{
      type: string;
      transmissions: QuestionAttachmentTransmissionApiType[];
    }>) => {
      const type = action.payload.type;

      state.tranmissionApis = {
        ...state.tranmissionApis,
        [type]: action.payload.transmissions,
      };
    },


    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////// search companies ///////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },

    setCriteria: (state, action: PayloadAction<CriteriaType | null>) => {
      state.criteria = action.payload;
    },
    setSearchedCompanies: (state, action: PayloadAction<CompanyType[]>) => {
      state.companiesResearched = action.payload;
    },
    //// filtration ///////
    setFilterCompanyState: (state, action: PayloadAction<string>) => {
      state.state = action.payload;
    },
    setFilterCompanyAccess: (state, action: PayloadAction<string>) => {
      state.access = action.payload;
    },
    setFilterCompanyNotification: (state, action: PayloadAction<string>) => {
      state.notification = action.payload;
    },

    setFilterConnector: (state, action: PayloadAction<string>) => {
      state.connector = action.payload;
    },
    setFilterByUserAffected: (state, action: PayloadAction<string>) => {
      state.userAffected = action.payload;
    },
    setFilterClosingMonth: (state, action: PayloadAction<string>) => {
      state.closingMonth = action.payload;
    },
    resetFilters: (state, action: PayloadAction<null>) => {
      state.state = "all";
      state.access = "all";
      state.notification = "all";
      state.connector = "all";
      state.userAffected = "all";
      state.closingMonth = "all";
    },
    ////  ///////

    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setSearchedCount: (state, action: PayloadAction<number>) => {
      state.companyPagesCount = action.payload;
    },
    setSortFieldName: (state, action: PayloadAction<string>) => {
      state.sortFieldName = action.payload;
    },
    setSortFieldDirection: (state, action: PayloadAction<"desc" | "asc">) => {
      state.sortFieldDirection = action.payload;
    },

    // search affectations
    setAffectations: (state, action: PayloadAction<UserType[]>) => {
      let temp = JSON.parse(JSON.stringify(state.companySelected));
      temp.affectations = action.payload;
      state.companySelected = temp;
    },
    addAffectation: (state, action: PayloadAction<UserType>) => {
      let temp = JSON.parse(JSON.stringify(state.companySelected));
      temp.affectations = [...temp.affectations, action.payload];
      state.companySelected = temp;
    },
    removeAffectation: (state, action: PayloadAction<UserType>) => {
      let temp = JSON.parse(JSON.stringify(state.companySelected));
      temp.affectations = temp.affectations.filter((item: UserType) => item.email !== action.payload.email);

      state.companySelected = temp;
    },

    setIsRestricted: (state, action: PayloadAction<boolean>) => {
      let temp = JSON.parse(JSON.stringify(state.companySelected));
      temp.isRestricted = action.payload;
      state.companySelected = temp;
    },
    // Logout
    deleteCompanies: (state, action: PayloadAction) => {
      state.companiesToAnswer = [];
      state.companySelected = null;
      state.companyPicture = null;
      state.search = "";
      state.criteria = null;
      state.companyPagesCount = 1;
      state.lastCompanyAction = null;
      state.companiesResearched = [];
      state.companiesResearchedAndFiltered = [];
      // PAGINATION
      state.currentPage = 1;
      state.sortFieldName = "code";
      state.sortFieldDirection = "asc";
      // FILTRATION
      state.userAffected = "all";
      state.access = "all";
      state.state = "all";
      state.notification = "all";
    },

    // QUESTION COUNT
    setQuestionCount: (state, action: PayloadAction<CompanyCounterType>) => {
      if (state.companySelected !== null) {
        const companySelectedCopy = JSON.parse(JSON.stringify(state.companySelected));
        companySelectedCopy.companyQuestionCount = action.payload;
        state.companySelected = companySelectedCopy;
      }
    },
    changeRole: (
      state,
      action: PayloadAction<{
        user: UserType;
        role: { isOwner: boolean; isAdmin: boolean; };
      }>,
    ) => {
      const companyCopy = JSON.parse(JSON.stringify(state.companySelected));

      const companyPermissionsIndex = companyCopy.companyPermissions.findIndex(
        (permission: CompanyPermissionType) => permission.user.id === action.payload.user.id,
      );

      companyCopy.companyPermissions[companyPermissionsIndex] = {
        ...companyCopy.companyPermissions[companyPermissionsIndex],
        ...action.payload.role,
      };

      const userIndex = companyCopy.users.findIndex((user: UserType) => user.id === action.payload.user.id);
      companyCopy.users[userIndex] = action.payload.user;

      state.companySelected = companyCopy;
    },
  },
});

export const {
  setCompanies,
  setMyCompanies,
  setCompanySelected,
  setDashboardCompanySelected,
  // updateDashboard,
  setExercicesCompanySelected,
  updateUser,
  setCompanySelectedAppairingKey,
  updateSelectedCompanyPicture,
  changeCompanyInformations,
  setLastCompanyAction,
  deleteCompanies,
  addCompany,
  setCompanySearchType,
  setCurrentPage,
  storeCollaborators,
  addNotificationUser,
  removeNotificationUser,
  addCollaborator,
  deleteCollaborator,
  addCategory,
  changeCategory,
  deleteCategory,
  storeCategories,
  setSearchedCompanies,
  setSortFieldName,
  setSortFieldDirection,
  setSearchedCount,
  setSearch,
  setCriteria,
  storeTransmissionMails,
  updateTransmissionMails,
  storeTransmissionApis,
  updateTransmissionApis,
  // filtration
  resetFilters,
  setFilterClosingMonth,
  setFilterConnector,
  setFilterByUserAffected,
  setFilterCompanyAccess,
  setFilterCompanyState,
  setFilterCompanyNotification,
  setAffectations,
  addAffectation,
  removeAffectation,
  setIsRestricted,
  setCompaniesToAnswer,
  setQuestionCount,
  setCompanyAffected,
  archiveQuestionCount,
  changeRole,
} = CompanySlice.actions;

export default CompanySlice.reducer;
